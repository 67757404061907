import React, { createElement, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { COOKIE, courseStatesConst, courseStatus, queryParams, TAGS } from '../utility/const';
import {
  addSubtitleParam,
  areAllActivitiesCompleted,
  canCancelBooking,
  canJoinSession,
  canSignUp,
  checkCourseState,
  getCoursesWithinLP,
  getDefaultSubtitle,
  getNextActivity,
  getNextLessonWithinLP,
  getParentCourse,
  getSessionsForModal,
  getTimeByString,
  isAnEvent,
  isChildOfAnEvent,
  isCompletedCourse,
  isExternalContentCourse,
  isLearningPath,
  isLivestreamCourse,
  isLpEnrolled,
  isMandatory,
  isMobile,
  isMultiActivity,
  isPathAbsolute,
  isPdfCourse,
  isStartedCourse,
  isUpcomingInCatalog,
  isVideoCourse,
  isVideoOrPodcast,
  isVirtualClassroom,
  joinSession,
  scrollToSessions,
} from '../utility/Api';
import {
  Course,
  CourseActivity,
  CourseLanguage,
  CourseSession,
  CoursesMap,
  LangMap,
  LangMapNotif,
  LevelEnrichedMap,
} from '../model/CoursesClass';
import * as actions from '../store/actions';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { DataLayerTracking, EventsTracking, TealiumEvents, tealiumEventsOnAction } from '@model/TrackingClass';
import { UserProfile } from '@model/User';
import { isLpBlocked, isStartedLp } from '@utility/LPUtility';
import {
  getLivestreamRecording,
  getLivestreamUrls,
  willLivestreamRecorded,
  isLivestreamNotYetFinished,
  isLivestreamFinished,
  getLivestreamCompletation,
} from '@utility/LivestreamUtility';
import CardLoader from './UI/CardLoader';
import { RootState } from 'src/store/store';
import { getVCRecCompletion, getVCRecording, getVCRecordingLinks, getVCRecordings } from '@utility/VCUtility';
import {
  isAssignmentLesson,
  isCourseMaster,
  isCourseOnBoarding,
  isOnboardingCompleted,
  isOneToOneLesson,
  isSupportLessonInOnboarding,
  isSupportMaterialLesson,
  printLiveDateCardOnboarding,
  printSessionDateCardOnboarding,
  saveLPInProgress,
} from '@utility/onBoardingCourseUtility';
import { isInPlan, isPurchased } from '@utility/ecommerceUtility';
import { isPlanExpired, selectSubscription, selectUserPlan } from '@utility/ecommercePlanUtility';
import { useSelector } from '@hooks/redux';
import { PUBLIC_URLS, styledLogUtagView, USER_URLS } from './link-utils';
import PlayIcon from './UI/PlayIcon';
import { getLessonCompletionPercentage, isCourseInSavingProgress } from '@utility/CourseUtility';
import clsx from '@utility/clsx';
import useDownloadCertificate from '@hooks/useDownloadCertificate';
import ButtonV2, { ButtonVariants } from './UI/ButtonV2';
import { matchPath } from 'react-router';
import { ButtonHarvardType, isCourseHarvard } from './Harvard/Harvard';
import LazySVG from './LazySvg';
import { createIcs } from '@utility/CalendarUtility';
import RibbonCourseEvolution from './UI/RibbonCourseEvolution';
import { isCourseCareerMaster } from '@utility/buildYourCareerUtility';
import { removeHashFromUrl } from '../utility/Api';
import { getActivityCompletionPercentage } from '@utility/activityUtility';
import { isInStandaloneMode } from '@utility/pwaUtility';
import { getCookie, removeCookie } from '@utility/cookie';
import { triggerTrackEvent } from '@utility/analytics-utils';

type ActionLabelType = {
  label: string | any;
  labelCancelVC?: string | any;
  action: () => () => void;
  isCourseDetailPresent?: boolean;
  label2?: string;
  action2?: () => () => void;
  actionCancelVC?: () => () => void;
  showPlayIcon?: boolean;
  playIconPercentage?: number;
  dateLabel?: string;
  className?: string;
  buttonVariantByAction?: string;
};

type Props = PropsFromRedux & {
  isLoading: boolean;
  course: Course;
  lang: LangMap;
  className?: string;
  courseStarted: (courseId: string) => void;
  closePopup?: () => void;
  toggleModal: (
    open: boolean,
    course?: Course,
    closeAll?: boolean,
    scrollToCurrentCourseSessions?: boolean
  ) => void;
  subtitle: CourseLanguage;
  toggleRatingModal: (value: boolean, course: Course) => void;
  enrollLp: (enroll: boolean, id: string) => void;
  isLpEnrolling: boolean;
  openActivity: (activity: CourseActivity, course: Course, subtitle: CourseLanguage) => void;
  userProfile: UserProfile;
  languages: Array<CourseLanguage>;
  coursesMap: CoursesMap;
  toggleIframePopup: (
    value: boolean,
    course: Course,
    url: string,
    urlToCheck: string,
    isVideo: boolean,
    urlsLivestream?: any,
    activityId?: string,
    subtitleCode?: string
  ) => void;
  isLessonWithinLp?: boolean;
  /**
   * @see getCourseDetails in courseAction.js
   *  Flag that enable to fetch the details of course before starting the course.
   *  If there are no details yet, button is disabled and click has no effect.
   *  */
  delayGetCourseDetails?: boolean;
  openConfirmBookingModal: (
    courseId: string,
    session: CourseSession,
    isSessionSignUp: boolean
  ) => void;
  showNextUpModal: boolean;
  toggleNextUpModal: (val) => void;
  getCourseDetails: (course: Course) => void;
  handleCloseIframe: (course: Course) => void;
  createSessionIcs: (courseId: string, sessionId?: string) => void;
  notifyRecordingLivestreamUpdate: (course: Course) => void;
  isLoadingNotifyRecordingLivestream: Map<string, boolean>;
  isLoadingNotifyLivestreamStarting: Map<string, boolean>;
  toggleModalCourseById: (courseId) => void;
  isLoadingCourse: { [courseId: string]: boolean };
  postAction?: () => void;
  forcePlayIcon?: boolean;
  canBePlayIcon?: boolean;
  playIconValue?: number;
  showDateCTA?: boolean;
  replaceCancelBookingWithAddCalendar?: boolean;
  isOnboardingHP?: boolean;
  downloadOnboardingCertificatePdf: (courseId: string) => void;
  isDownloadingOnboardingCertificatePdf: boolean;
  buttonVariant?: ButtonVariants;
  buttonSmall?: boolean;
  suffix?: React.ReactNode;
  'data-element-id'?: string;
  ctaCard?: boolean;
  harvardCTA?: JSX.Element;
  multiActivityVCSession?: boolean;
  vcSession?: CourseSession;
  isClickedFromOnboarding?: boolean;
  vcLesson?: CourseActivity;
  fatherCourseId?: string;

  /**
   * @see EducationalPathsProgramButton
   * Change style if used inside a card of education path
   * */
  variantInsideCardEducationalPath?: boolean;

  /*
   * Prop used for using DetailsSingleActivityButton logic with different UI
   * element. Instead of editing the JSX element that this component returns,
   * here you can pass a TYPE of React component that on handleClick or an event that
   * you decide, calls the function handleButtonClick that handle the entire logic and
   * functionalities that includes.
   * @props
   * <ul>
   * <li>typeElement: React.ElementType type of component to print</li>
   * <li>props: object props custom to inject into that React.ElementType</li>
   * <li>useActionCustom:
   *   <ul>
   *      <li>true if need to ignore all logic built into DetailsSingleActivityButton</li>
   *     <li>false if need to choose an action from the logic built  into DetailsSingleActivityButton</li>
   *   </ul></li>
   * </ul>
   *
   *
   * @see getActionNeeded
   * @example
   * // This example show how @see EducationalPathsProgramButton is used for the presentation layer and
   * //  it's connected to DetailsSingleActivityButton via this prop. Inside of EducationalPathsProgramButton
   * //  there is handle named handleClickFromDetailsSingleActivityButton which is called when it's clicked and
   * //  launch handeButtonClick as callback.
   * <DetailsSingleActivityButton
   *   course={course}
   *   subtitle={null}
   *   lang={lang}
   *   isLoading={false}
   *   delayGetCourseDetails
   *   forcePlayIcon
   *   useCustomButton={EducationalPathsProgramButton}
   * />
   *
   */
  useCustomButton?: {
    typeElement: React.ElementType;
    props?: object;
    /** @see getActionNeeded */
    useActionCustom?: boolean;
  } | null;
};
const DetailsSingleActivityButton = ({
  useCustomButton = null,
  isLoading = false,
  course,
  lang,
  className,
  courseStarted,
  toggleIframePopup,
  toggleModal,
  subtitle,
  toggleRatingModal,
  enrollLp,
  isLpEnrolling,
  openActivity,
  userProfile,
  languages,
  coursesMap,
  isLessonWithinLp,
  delayGetCourseDetails,
  openConfirmBookingModal,
  showNextUpModal,
  toggleNextUpModal,
  getCourseDetails,
  handleCloseIframe,
  createSessionIcs,
  notifyRecordingLivestreamUpdate,
  isLoadingNotifyRecordingLivestream,
  isLoadingNotifyLivestreamStarting,
  toggleModalCourseById,
  isLoadingCourse,
  postAction,
  forcePlayIcon,
  canBePlayIcon,
  playIconValue,
  showDateCTA,
  replaceCancelBookingWithAddCalendar,
  isOnboardingHP,
  buttonVariant,
  buttonSmall,
  downloadOnboardingCertificatePdf,
  setShowModalExpiredInPlan,
  plan,
  isDownloadingOnboardingCertificatePdf,
  ctaCard,
  suffix,
  'data-element-id': dataElementId,
  harvardCTA,
  multiActivityVCSession,
  variantInsideCardEducationalPath,
  vcSession,
  isClickedFromOnboarding= false,
  vcLesson,
  fatherCourseId
}: Props) => {
  const [bShowSavingProgressCourse, setShowSavingProgressCourse] = useState<boolean>(isCourseInSavingProgress(course)) 
  const [label, setLabel] = useState('');
  const [action, setAction] = useState(null);
  const [label2, setLabel2] = useState('');
  const [action2, setAction2] = useState(null);
  const [labelCancelVC, setLabelCancelVC] = useState('');
  const [actionCancelVC, setActionCancelVC] = useState(null);

  const [showPlayIcon, setShowPlayIcon] = useState<boolean>(false);
  const [playIconPercentage, setPlayIconPercentage] = useState<number>(0);

  const [dateLabel, setDateLabel] = useState<string>('');
  const [classNameByAction, setClassNameByAction] = useState<string>('');
  const [buttonVariantByAction, setButtonVariantByAction] = useState<string>('');

  const isLoadingGetUserRelevantSessionById = useSelector(state => state.utils.isLoadingGetUserRelevantSessionsById);
  const [isWaitingCourseDetails, setIsWaitingCourseDetails] = useState<boolean>(false);

  const [windowRef, setWindowRef] = useState(null);

  const { t } = useTranslation();
  const history = useHistory();

  const { UPCOMING, SCHEDULED, MISSED, ATTENDED } = courseStatesConst;

  const orderSubscription = useSelector(selectSubscription);
  const userPlan = useSelector(selectUserPlan);
  const langNotif: LangMapNotif = useSelector(state => state.utils.langNotif);
  const isGettingCourseCompletionById = useSelector(
    state => state.course.isGettingCourseCompletionById
  );
  const labelL1Map: LevelEnrichedMap = useSelector(state => state.utils.labelL1Map);
  const [handleDownloadClick, shouldShowDownloadButton] = useDownloadCertificate(
    course,
    isCourseOnBoarding(course)
  );

  let isOnboardingPage =
    window.location.href.includes(USER_URLS.ONBOARDING_COURSE.URL) &&
    !window.location.href.includes(queryParams.COURSE_DETAIL);

  /*
   * @name getActionNeeded
   * Getting specific action to execute a specific callback
   * */
  function getActionNeeded(): ActionLabelType {
    if (isVirtualClassroom(course)) return handleVCActivityButton();
    if (isMultiActivity(course)) return handleMultiActivityButton();
    if (isAnEvent(course)) return handleEvent();
    if (isLearningPath(course)) return handleLPButton();
    if (isLivestreamCourse(course)) return handleLivestreamButton();

    // default
    return handleLessonButton();
  }

  useEffect(() => {
    if (!lang || !course) {
      setLabel('');
      setAction(null);
      return;
    }

    if (course.isStatic) {
      setLabel(t('LOGIN_REGISTER'));
      setAction(() => goToLoginAction);
      return;
    }

    // if(useCustomButton?.useActionCustom ) return

    let actionLabel: ActionLabelType = { label: '', action: null };

    actionLabel = getActionNeeded();
    //now we have the course details to run the action
    if (isWaitingCourseDetails && actionLabel.isCourseDetailPresent) {
      setIsWaitingCourseDetails(false);
      //run action
      if (actionLabel.action) {
        actionLabel.action()();
      }
    }

    setLabel(actionLabel.label);
    setAction(actionLabel.action);

    if (actionLabel.labelCancelVC && actionLabel.actionCancelVC) {
      setLabelCancelVC(actionLabel.labelCancelVC);
      setActionCancelVC(actionLabel.actionCancelVC);
    } else {
      setLabelCancelVC('');
      setActionCancelVC(null);
    }

    if (actionLabel.label2 && actionLabel.action2) {
      setLabel2(actionLabel.label2);
      setAction2(actionLabel.action2);
    } else {
      setLabel2('');
      setAction2(null);
    }

    setShowPlayIcon(!!actionLabel.showPlayIcon);
    setPlayIconPercentage(
      playIconValue || playIconValue === 0 ? playIconValue : actionLabel.playIconPercentage
    );

    setDateLabel(actionLabel.dateLabel);
    setClassNameByAction(actionLabel.className);
    setButtonVariantByAction(actionLabel.buttonVariantByAction);
  }, [
    course,
    lang,
    subtitle,
    coursesMap,
    languages,
    userProfile,
    isLpEnrolling,
    shouldShowDownloadButton,
  ]);

  const handleLivestreamButton = (crs: Course = null): ActionLabelType => {
    if (!crs) {
      crs = course;
    }

    //if livestream completed or missed
    if (isLivestreamFinished(crs, userProfile)) {
      let recording = getLivestreamRecording(crs, userProfile);
      if (recording.length === 1) {
        //1 recording case
        let completationUrl = '';
        completationUrl = getLivestreamCompletation(crs, userProfile, null, recording[0]);
        let completeRecording = recording[0].includes('?')
          ? recording[0] + '&api=1'
          : recording[0] + '?api=1';
        //show VIEW RECORDING button
        return {
          label: lang.ACTIVITY_BUTTON_VIEW_RECORDING,
          action: () => () => {
            toggleIframePopup(true, crs, completeRecording, completationUrl, false);
          },
        };
      } else if (willLivestreamRecorded(crs, userProfile) && !crs.vimeoRecNotification) {
        //show NOTIFY ME button to receive a notification when recording is uploaded
        return {
          label: lang.NOTIFY_ME,
          action: () => () => {
            notifyRecordingLivestreamUpdate(crs);
          },
        };
      } else {
        return {
          label: '',
          action: null,
        };
      }
    }

    const dateLabel = !!showDateCTA && printLiveDateCardOnboarding(crs.liveInfo?.[0], lang);

    // JOIN
    const urlsLivestream = getLivestreamUrls(crs);
    if (urlsLivestream.liveUrl && crs.showJoin) {
      return {
        label: lang.JOIN_LABEL,
        action: () => () => {
          toggleIframePopup(true, crs, null, null, false, urlsLivestream);
        },
        dateLabel: dateLabel,
      };
    }

    //LIVESTREAM IN UPCOMING
    if (isLivestreamNotYetFinished(crs, userProfile)) {
      return {
        label: lang.SAVE_CALENDAR,
        action: () => () => createSessionIcs(crs.courseIdMaster),
        dateLabel: dateLabel,
        //OUT OF SCOPE NOTIFY ME AT LIVE STARTING
        // label2: lang.NOTIFY_ME_LIVE_STARTING,
        // action2: crs.liveInfo?.[0]?.willBeNotifiedLivestreamStart ? null : () => () => notifyLivestreamStarting(crs),
      };
    }

    return {
      label: '',
      action: null,
    };
  };

  const handleLessonButton = (crs: Course = null): ActionLabelType => {
    let sub = subtitle;
    if (!crs) {
      crs = course;
    } else {
      sub = getDefaultSubtitle(crs, languages, userProfile);
    }

    const isPartOfExpiredPlan = isInPlan(crs) && isPlanExpired(orderSubscription, userPlan);

    //store if course details are present
    const isCourseDetailPresent = crs.courseDetails?.length > 0;

    //handle label
    let label = lang.LAUNCH_LABEL;
    let buttonVariantByAction = undefined;

    if (isAssignmentLesson(crs)) {
      label = lang.ASSIGNMENT_CTA;
    } else if (isOneToOneLesson(crs)) {
      label = lang.ONE_TO_ONE_CTA;
    } else if (isSupportMaterialLesson(crs)) {
      label = lang.SUPPORT_MATERIAL_CTA;
    } else if (crs.userCourseStatus === courseStatus.ENROLLED && !isPdfCourse(crs)) {
      label = lang.CONTINUE_LABEL;
    }

    //show play icon?
    const showPlayIcon = isSupportLessonInOnboarding(crs) ? false : !!canBePlayIcon;

    return {
      label: label,
      action: () =>
        !isPartOfExpiredPlan
          ? () => openCourseActivity(crs, sub)
          : () => setShowModalExpiredInPlan(true),
      isCourseDetailPresent: isCourseDetailPresent,
      showPlayIcon: showPlayIcon,
      playIconPercentage: getLessonCompletionPercentage(crs),
      buttonVariantByAction: buttonVariantByAction,
    };
  };

  /** 
   * Function to format url of VC in case it arrives without some
   * query parameters. Query parameters needed are "ap=1&mute=1&api=1"
   * LEON-5720
  */ 
  const formatVcDirectUrl = (url: string): string => {
    return `${url?.split('?')[0]}?ap=1&mute=1&api=1`
  }

  const handleVCActivityButton = (crs: Course = null): ActionLabelType => {
    if (!crs) {
      crs = course;
    }

    const { courseStates } = crs;

    /**
     * MULTIPLE RECORDINGS
     * NOT HANDLED YET, IT WILL BE USEFUL IN THE FUTURE
     * WHEN THERE WILL BE MULTIPLE RECORDINGS
     * 
     * const recordingUrls = getVCRecordings(crs);
     * 
     * if (recordingUrls && recordingUrls.length === 1) {
        return {
          label: lang.ACTIVITY_BUTTON_VIEW_RECORDING,
          action: () => () => {
            openRecordings(recordingUrls[0]);
          },
        };
      } else if (recordingUrls?.length > 1) {
        return {
          label: '',
          action: null,
        };
      }
    */

    if(!isMultiActivity(crs) && !isLessonWithinLp) {

      const {completionurl,directurl} = getVCRecordingLinks(crs)
      // TO TEST A CROSSCAST VIDEO
      // const tempRecUrl: string = "https://luxottica.player.crosscast-system.com/video/43948446-4912-441a-8cb1-cc8a93d077ad?fullscreen=1&ap=1&mute=1&api=1&cc_lang_pref=en"
      
      if (directurl) {
        return {
          label: lang.ACTIVITY_BUTTON_VIEW_RECORDING,
          action: () => () => {
            // openRecordings(recordingUrl);
            dispatch(toggleIframePopup(
              true, 
              crs,
              formatVcDirectUrl(directurl), 
              completionurl,  
              true
            ))
          },
        };
      } else if (directurl === '') {
        return {
          label: '',
          action: null,
        };
      }
    }

if(!isCompletedCourse(crs)){
    let sessions;
    //AT THE MOMENT multiactivity does not support vc multi sessions, the field facetofaceid has to be removed at februaru 2024 (homepage optimization)
    /*if (vcLesson) {
      sessions = getSessionsForModal(crs, vcLesson);
    } else {
      sessions = getSessionsForModal(crs);
    }*/
    sessions = getSessionsForModal(crs);
    for (const session of sessions) {
      const dateLabel = !!showDateCTA && printSessionDateCardOnboarding(session, lang);

      //SIGN UP
      if (
        (checkCourseState(courseStates, UPCOMING) || isUpcomingInCatalog(crs)) &&
        canSignUp(session, crs)
      ) {
        //if only one session or is  --> book to session
        if (sessions.length === 1) {
          return {
            label: lang.SIGNUP_CTA,
            action: () => () => openConfirmBookingModal(crs.courseIdMaster, session.id, true),
            dateLabel: dateLabel,
          };
        } else if (multiActivityVCSession && !vcSession) {
          //single vc
          return {
            label: lang.SIGNUP_CTA,
            action: () => () => openConfirmBookingModal(crs.courseIdMaster, session.id, true),
            dateLabel: dateLabel,
          };
        } else if (multiActivityVCSession && vcSession) {
          //multi vc
          return {
            label: lang.SIGNUP_CTA,
            action: () => () => openConfirmBookingModal(crs.courseIdMaster, vcSession.id, true),
            dateLabel: dateLabel,
          };
        } else {
          return {
            label: lang.ACTIVITY_BUTTON_CHOOSE_SESSION,
            action:
              crs === course && !isLessonWithinLp
                ? () => scrollToSessions
                : () => () => {
                    toggleModal(true, crs, false, true);
                  },
          };
        }
      }

      // JOIN
      if (canJoinSession(session) && crs.showJoin) {
        console.log('canjoinsession');
        return {
          label: lang.JOIN_LABEL,
          action: () => () => joinSession(crs, session, toggleRatingModal),
          showPlayIcon: !!canBePlayIcon,
        };
      }
      // MESSAGE ATTENDED
      // if (isAttendedSession(session)) {
      // }
      // MESSAGE CHECK ATTENDANCE
      // if (isScheduledSession(session) && canSeeCheckMessage(session)) {
      // }

      // CANCEL BOOKING
      if (checkCourseState(courseStates, SCHEDULED) && canCancelBooking(session)) {
        if (replaceCancelBookingWithAddCalendar) {
          //CTA add calendar
          return {
            label: (
              <div>
                <LazySVG
                  src="/images/svg-icons/calendar-3.svg"
                  alt={lang?.ACTIVITY_BUTTON_CALENDAR}
                />
                <span className="phone-and-tablet-portrait">{lang?.ACTIVITY_BUTTON_CALENDAR}</span>
              </div>
            ),
            action: () => () => createSessionIcs(crs.courseIdMaster, session.id),
            dateLabel: dateLabel,
            labelCancelVC: (
              <div>
                <LazySVG
                  src="/images/svg-icons/trash.svg"
                  alt={lang?.POP_UP_RECOVER_CANCEL}
                  className="trash-icon-vc"
                />
                <span className="phone-and-tablet-portrait">{lang?.POP_UP_RECOVER_CANCEL}</span>
              </div>
            ),
            actionCancelVC: () => () =>
              openConfirmBookingModal(crs.courseIdMaster, session.id, false),
          };
        }
      }
    }
  }

    return {
      label: '',
      action: null,
    };
  };

  const handleMultiActivityButton = (crs: Course = null): ActionLabelType => {
    let sub = subtitle;
    if (!crs) {
      crs = course;
    } else {
      sub = getDefaultSubtitle(crs, languages, userProfile);
    }

    const label =
      isStartedCourse(crs) && !areAllActivitiesCompleted(crs.courseDetails)
        ? lang.CONTINUE_LABEL
        : lang.LAUNCH_LABEL;

    //store if course details are present
    const isCourseDetailPresent = crs.courseDetails?.length > 0;

    if (course.ctype.includes('virtual_classroom')) {
    }

    //if course details are not present --> set action to get them
    if (!isCourseDetailPresent) {
      return {
        label: label,
        action: () => () => {
          getCourseDetails(crs);
          setIsWaitingCourseDetails(true);
        },
        isCourseDetailPresent: false,
        showPlayIcon: !!canBePlayIcon,
        playIconPercentage: getLessonCompletionPercentage(crs),
      };
    }

    let firstActivity = getNextActivity(crs);

    return {
      action: () => () => openActivity(firstActivity, crs, sub),
      label: label,
      isCourseDetailPresent: true,
      showPlayIcon: !!canBePlayIcon,
      playIconPercentage: getActivityCompletionPercentage(firstActivity),
    };
  };

  const handleOnboardingButton = (course, nextLesson, actionLesson) => {
    if (!nextLesson) {
      nextLesson = getNextLessonWithinLP(course, coursesMap, userProfile);
    }

    if (isCourseMaster(course)) {
      //SCROLL TO NEXT LESSON IN ONBOARDING PAGE

      // const lessonWithinLP = course.learningPath.find(
      //   a => a.parentId === nextLesson.courseIdMaster
      // );
      // const topic2 = lessonWithinLP.topicKey2;

      if (
        matchPath(window.location.pathname, {
          path: USER_URLS.ONBOARDING_COURSE_ID.URL,
          exact: true,
        }) &&
        window.location.pathname.includes(course.courseIdMaster)
      ) {
        //if onboarding page of the current course
        let url = window.location.pathname;
        url += '?' + queryParams.ONBOARDING_SCROLL_TO_LESSON + '=' + nextLesson.courseIdMaster;

        history.replace(url);
      } else {
        //if some other page
        history.push(
          USER_URLS.ONBOARDING_COURSE_ID.URL.replace(':id', course.courseIdMaster) +
            '?' +
            queryParams.ONBOARDING_SCROLL_TO_LESSON +
            '=' +
            nextLesson.courseIdMaster
        );
      }
    } else {
      //LAUNCH LESSON CONTENT
      if (actionLesson) {
        actionLesson()();
      }

      if (!isLpEnrolling && !isLpEnrolled(course)) {
        enrollLp(true, course.courseIdMaster);
      }
    }
  };

  const handleCareerMasterBannerCta = (course, nextLesson, actionLesson, actionLabel) => {
    if (!nextLesson) {
      nextLesson = getNextLessonWithinLP(course, coursesMap, userProfile);
    }

    switch (actionLabel) {
      case lang?.DISCOVER_MORE:
        // GO TO MASTER PDP
        history.push(
          USER_URLS.MANAGEMENT_DEVELOPMENT_PROGRAM_PROGRAM.URL.replace(':id', course.courseIdMaster)
        );
        break;
      case lang?.LAUNCH_LABEL:
        // GO TO MASTER PDP
        if (
          matchPath(window.location.pathname, {
            path: USER_URLS.MANAGEMENT_DEVELOPMENT_PROGRAM_PROGRAM.URL,
            exact: true,
          }) &&
          window.location.pathname.includes(course.courseIdMaster)
        ) {
          //if onboarding page of the current course
          let url = window.location.pathname;
          url += '?' + queryParams.ONBOARDING_SCROLL_TO_LESSON + '=' + nextLesson.courseIdMaster;

          history.replace(url);
        } else {
          //if some other page
          history.push(
            USER_URLS.MANAGEMENT_DEVELOPMENT_PROGRAM_PROGRAM.URL.replace(
              ':id',
              course.courseIdMaster
            ) +
              '?' +
              queryParams.ONBOARDING_SCROLL_TO_LESSON +
              '=' +
              nextLesson.courseIdMaster
          );
        }
        break;
      case lang?.CONTINUE_LABEL:
        //LAUNCH LESSON CONTENT
        if (actionLesson) {
          actionLesson()();
        }
        if (!isLpEnrolling && !isLpEnrolled(course)) {
          enrollLp(true, course.courseIdMaster);
        }
        break;

      default:
        break;
    }
  };

  const handleEvent = (): ActionLabelType => {
    let actionLabel: ActionLabelType = { label: '', action: null };
    const actionLesson = (actionLabel.action = () => () => {});
    actionLabel.label = lang.LAUNCH_LABEL;
    actionLabel.action = () => () => handleClickScroll(1);
    actionLesson();
    actionLabel.label = lang.ACTIVITY_BUTTON_CHOOSE_SESSION;
    actionLabel.buttonVariantByAction = 'primary';
    return actionLabel;
  };

  const handleLPButton = (): ActionLabelType => {
    //action of the next lesson
    let actionLabel: ActionLabelType = { label: '', action: null };

    /* if (isLpBlocked(course, coursesMap)) {
      actionLabel = {
        action: null,
        label: '',
      };
    } */
  
      //only for onboarding courses, if the onboarding has every lesson completed or it's completed --> download celebration certificate
      if (isOnboardingCompleted(course) && shouldShowDownloadButton) {
        actionLabel.label = lang.CTA_DOWNLOAD_CERTIFICATE;

        actionLabel.action = () => () => handleDownloadClick(null);
        actionLabel.className = 'download-certificate-onboarding';

        return actionLabel;
      }

      const nextLesson = getNextLessonWithinLP(course, coursesMap, userProfile);
      if (nextLesson) {
        if (isVirtualClassroom(nextLesson)) {
          actionLabel = handleVCActivityButton(nextLesson);
        } else if (isMultiActivity(nextLesson)) {
          actionLabel = handleMultiActivityButton(nextLesson);
        } else if (isLivestreamCourse(nextLesson)) {
          actionLabel = handleLivestreamButton(nextLesson);
        } else {
          actionLabel = handleLessonButton(nextLesson);
        }
        if (isCompletedCourse(course)) {
          actionLabel.label = lang.RETAKE_LABEL;
        } else if (isStartedCourse(course)) {
          if (isStartedLp(course, coursesMap)) {
            actionLabel.label = lang.CONTINUE_LABEL;
          } else {
            actionLabel.label = lang.LAUNCH_LABEL;
          }

          if (isCourseMaster(course)) {
            // HANDLE CARRER BANNER LABEL IN BYC PAGE CASE
            if(isCourseCareerMaster(course) && location?.pathname === USER_URLS.BUILD_YOUR_CAREER.URL) {

              const actionLesson = actionLabel.action;
              actionLabel.action = () => () => {
                handleCareerMasterBannerCta(course, nextLesson, actionLesson,actionLabel.label);
              };
            } else {
              actionLabel.label = lang.MASTER_GO_TO_LESSON;
              const actionLesson = actionLabel.action;
              actionLabel.action = () => () => {
                handleOnboardingButton(course, nextLesson, actionLesson);
              };
            }
          } else if (isOnboardingHP && isCourseOnBoarding(course)) {
            const actionLesson = actionLabel.action;
            actionLabel.action = () => () => {
              actionLesson()();

              if (actionLabel.isCourseDetailPresent) {
                const lessonWithinLP = course.learningPath.find(
                  a => a.parentId === nextLesson.courseIdMaster
                );
                const topic2 = lessonWithinLP.topicKey2;
                history.push(
                  USER_URLS.ONBOARDING_COURSE_ID.URL.replace(':id', course.courseIdMaster) +
                    '?scrollToTopic=' +
                    topic2
                );
              }
            };
          }
        } else {
          actionLabel.label = lang.LAUNCH_LABEL;

          if (isCourseOnBoarding(course)) {
            if (isOnboardingHP) {
              //go to program page
              actionLabel.action = () => () => {
                history.push(
                  USER_URLS.ONBOARDING_COURSE_ID.URL.replace(':id', course.courseIdMaster)
                );
              };
            } else {
              if (isCourseMaster(course) && !isCourseCareerMaster(course)) {
                actionLabel.label = lang.MASTER_GO_TO_LESSON;
                const actionLesson = actionLabel.action;
                actionLabel.action = () => () => {
                  handleOnboardingButton(course, nextLesson, actionLesson);
                };
              }
              if (
                isCourseMaster(course) &&
                isCourseCareerMaster(course) &&
                location?.pathname === USER_URLS.BUILD_YOUR_CAREER.URL
              ) {
                actionLabel.label = !isPurchased(course) ? lang.DISCOVER_MORE : lang.LAUNCH_LABEL;
                const actionLesson = actionLabel.action;
                actionLabel.action = () => () => {
                  handleCareerMasterBannerCta(course, nextLesson, actionLesson, actionLabel.label);
                };
              }
            }
          }
        }
      }

      if(isCourseOnBoarding(course)){
        //if LP is not enrolled --> enroll and start next lesson at the same time
        // https://luxotticaretail.atlassian.net/browse/LEON-4453
        const sub = getDefaultSubtitle(nextLesson, languages, userProfile);
        if (!isLpEnrolled(course) && !isAnEvent(course)) {
          actionLabel.label = lang.LAUNCH_LABEL;
          actionLabel.action = () => () =>{ //enroll lp from start button in the program page
           enrollLp(true, course.courseIdMaster);
            openCourseActivity(nextLesson, sub)
          }
        }
      }
      else{
        //if LP is not enrolled --> enroll and start next lesson at the same time
        if (!isLpEnrolled(course) && !isCourseOnBoarding(course) && !isAnEvent(course)) {
          const actionLesson = (actionLabel.action = () => () => {});
          actionLabel.label = lang.LAUNCH_LABEL;
          actionLabel.action = () => () => enrollLp(true, course.courseIdMaster);
          actionLesson();

          //EXCEPTION for onboarding course --> do not show enroll label
          actionLabel.label = lang.ENROLL_LABEL;
          //enroll button has "primary" variant
          actionLabel.buttonVariantByAction = 'primary';
        }

        //in case of events -> BUTTON CHOOSE SESSION with drag down to the sessions
        if (!isLpEnrolled(course) && !isCourseOnBoarding(course) && isAnEvent(course)) {
          const actionLesson = (actionLabel.action = () => () => {});
          actionLabel.label = lang.LAUNCH_LABEL;
          actionLabel.action = () => () => handleClickScroll(1);
          actionLesson();
          actionLabel.label = lang.ACTIVITY_BUTTON_CHOOSE_SESSION;
          actionLabel.buttonVariantByAction = 'primary';
        }

      }

      // CareerMaster course not purchased yet
      if (
        isCourseCareerMaster(course) &&
        !isPurchased(course) &&
        location?.pathname?.includes('build-your-career')
      ) {
        actionLabel.label = lang.DISCOVER_MORE;
        const actionLesson = actionLabel.action;
        actionLabel.action = () => () => {
          handleCareerMasterBannerCta(course, nextLesson, actionLesson, actionLabel.label);
        };
      }
  

    return actionLabel;
  };

  const goToLogin = () => {
    history.push(PUBLIC_URLS.LOGIN.URL);
  };

  const goToLoginAction = (): void => {
    toggleModal(false);
    document.body.style.overflow = 'auto';
    goToLogin();
  };

  const openRecordings = link => {
    window.open(link);
  };

  const handleRecording = (recording, course, completationUrl) => {
    toggleIframePopup(true, course, recording, completationUrl, false);
  };

  const openCourseActivity = (crs: Course, sub: CourseLanguage): void => {
    if (crs.isStatic) {
      toggleModal(false);
      document.body.style.overflow = 'auto';
      goToLogin();
      return;
    }

    const handleOnboardingHP = isOnboardingHP && isCourseOnBoarding(course);
    let iOS = isMobile.iOS() && !window.MSStream;
    if (!(crs.courseDetails?.length > 0)) {
      getCourseDetails(crs);
      setIsWaitingCourseDetails(true);
      if (((iOS && isPdfCourse(crs)) || isExternalContentCourse(crs)) && !handleOnboardingHP) {
        //open new browser tab for ios --> https://luxotticaretail.atlassian.net/browse/UU-2272
        setWindowRef(window.open());
      }
      return;
    }

    const activityInfo = getCourseActivityUrl(crs);
    let url = activityInfo.url;
    let urlToCheck = activityInfo.urlToCheck;
    let type = activityInfo.type;
    let subCode = sub?.code;
    if (!subCode) {
      subCode = crs.language;
    }

    if (isVideoOrPodcast(crs) && !handleOnboardingHP) {
      toggleIframePopup(
        true,
        crs,
        addSubtitleParam(url, subCode),
        urlToCheck,
        true,
        null,
        activityInfo.moduleId,
        subCode
      );
    } else {
      if (
        ((iOS && type && type.toLowerCase() === 'resource') ||
          (type && type.toLowerCase() === 'url')) &&
        !handleOnboardingHP
      ) {
        console.error('windowRef', { windowRef });
        if (windowRef) {
          windowRef.location = url;
          console.error('window opened by windowRef');
          setWindowRef(null);
        } else {
          //open course in another tab
          const newWindow = window.open(url);
          if (!newWindow) {
            //if the new window is not opened --> open course detail
            console.error('window not opened');

            toggleModalCourseById(crs.courseIdMaster);
          } else {
            handleCloseIframe(crs);
          }
        }
      } else {
        if (!handleOnboardingHP) {
          toggleIframePopup(true, crs, url, null, false, null, activityInfo.moduleId, subCode);
        }
      }
    }

    if (crs.userCourseStatus === 'available') courseStarted(crs.courseIdMaster);
  };

  const getCourseActivityUrl = crs => {
    let url = '';
    let urlToCheck = null;
    let type = null;
    let moduleId = '';

    if (!crs) {
      return {
        url,
        urlToCheck,
        type,
      };
    }

    const { courseDetails } = crs;

    courseDetails?.forEach(activity => {
      if (activity.sectionName !== 'completed') {
        if (
          isVideoOrPodcast(crs) &&
          crs.courseDetails[0].content[0].tags.length > 0 &&
          crs.courseDetails[0].content[0].tags.includes(TAGS.publishDirect)
        ) {
          url = activity.content[0].directUrl;
          urlToCheck = activity.content[0].url;
        } else {
          url = activity.content[0].url;
        }

        type = activity.content[0].type;
        moduleId = activity.content[0].moduleId;
      }

      //prevent relative path
      if (!isPathAbsolute(url)) {
        url = '';
      }
    });

    return {
      url,
      urlToCheck,
      type,
      moduleId,
    };
  };

  const isDisabled = (): boolean => {
    let retVal: boolean = true;

    if (!course) {
      return true;
    }

    if (course.isStatic) {
      return false;
    }

    if (isLearningPath(course)) {
      return false;
    }

    //show button enabled even if the course details are not present
    if (delayGetCourseDetails && !(course.courseDetails?.length > 0)) {
      return false;
    }

    course.courseDetails?.forEach(activity => {
      if (activity.sectionName !== 'completed' && activity.content?.[0]?.available) {
        retVal = false;
      }
    });

    //<editor-fold desc="DetailsSingleActivityButton.tsx > isDisabled - line 900 at 08/09/2023 14:07:52">
    // console.group('DetailsSingleActivityButton.tsx > isDisabled - line 900 at 08/09/2023 14:07:52');
    console.groupEnd();
    //</editor-fold>

    if (
      !isVirtualClassroom(course) &&
      !isMultiActivity(course) &&
      !isLearningPath(course) &&
      !isLivestreamCourse(course) &&
      !getCourseActivityUrl(course)?.url
    ) {
      //if the url is not valid --> disable the button
      return true;
    }

    return retVal;
  };
  const dispatch = useDispatch();

  const handleButtonClick = (e,  secondaryAction = false) => {
    // //<editor-fold desc="DetailsSingleActivityButton.tsx > LogCourse - line 1074 at 03/10/2023 15:58:40 by e.cavallo">
    // if (course) {
    //   console.groupCollapsed('DetailsSingleActivityButton.tsx > LogCourse - line 1074 at 03/10/2023 15:58:40');
    //   console.table({'courseId': course.courseId, 'courseFullName': course.courseFullName, 'status':course?.userCourseStatus} )
    //   console.log(course)
    //   console.groupEnd();
    // }
    // //</editor-fold>

    removeHashFromUrl()
    //if button is loading --> return
    if (isLpEnrolling) {
      return;
    }

    if (!course.isStatic && !isVirtualClassroom(course)) {
      let courseContextIdFromSessionStorage = window?.sessionStorage.getItem("Products_ContextId_Array") ?? "";
      if(courseContextIdFromSessionStorage === "undefined") courseContextIdFromSessionStorage = ""
      let utagEventData: DataLayerTracking & tealiumEventsOnAction = {
        Products_Category_Array: course.catalogTypes.map(catalogType => {
          let categoryName = labelL1Map?.[catalogType]?.label;
          return categoryName?.toLowerCase();
        }),
        Products_Id_Array: [course.courseIdMaster],
        Products_ModelName_Array: [course.courseFullName?.toLowerCase()],
        Products_Language_Array: [course?.language],
        Events_CourseStart: '1',
        Products_Mandatory_Array: isMandatory(course) ? ['1'] : ['0'],
        Products_ContextId_Array: fatherCourseId ? 
          [fatherCourseId] : courseContextIdFromSessionStorage ? [courseContextIdFromSessionStorage] : [""]
      };

      if (isStartedCourse(course)) {
        if(isLearningPath(course) && isLpEnrolled(course)) {
          if(checkCourseState(course?.courseStates, courseStatesConst.IN_PROGRESS) && window.location.search !== "") {
            const lpChildren = getCoursesWithinLP(course, coursesMap)
            if(!lpChildren || lpChildren?.length == 0 || !lpChildren?.some(child => isCompletedCourse(child))) {
              styledLogUtagView('Course start event', utagEventData);
              triggerTrackEvent(utagEventData); 
            }
          }
        }
      } else {
        if((!isLearningPath(course) || isCourseOnBoarding(course)) && !isCompletedCourse(course)) {
          if(!isLivestreamCourse(course)) {
            styledLogUtagView('Course start event', utagEventData);
            triggerTrackEvent(utagEventData); 
          }
        }
      }
    }

    e?.stopPropagation();
    if (action || (secondaryAction && action2)) {
      if (showNextUpModal) {
        toggleNextUpModal(false);
      }

      if (!secondaryAction) {
        action(subtitle?.code);
      } else {
        action2(subtitle?.code);
      }

      if (postAction) {
        postAction();
      }
    }

    //enroll onboarding if you're starting a lesson in it
    course?.parentLP?.forEach(lpId => {
      const lp = coursesMap[lpId.courseId];
      if (isCourseOnBoarding(lp) && !isLpEnrolled(lp) && !isLpEnrolling && (isClickedFromOnboarding || localStorage.getItem('arrivedFromOnboarding') == 'true') ) {
        console.log("enrolling.......")
        localStorage.removeItem('arrivedFromOnboarding');
        enrollLp(true, lpId.courseId); 
      }
    });
  };

  const isLoadingButton = () => {
    return (
      isWaitingCourseDetails ||
      isLpEnrolling ||
      isLoadingNotifyRecordingLivestream?.[course.courseIdMaster] ||
      isLoadingNotifyLivestreamStarting?.[course.courseIdMaster] ||
      isLoadingCourse?.[course.courseIdMaster] ||
      isDownloadingOnboardingCertificatePdf ||
      (isOnboardingCompleted(course) && isGettingCourseCompletionById) || isLoadingGetUserRelevantSessionById
    );
  };

  const saveLPInProgress = ()=>{
    // check parentLP of course
    if(course?.parentLP?.length > 0){
      const parentLP = course?.parentLP[0];

      if(bShowSavingProgressCourse){
        dispatch(actions.setLPInSavingProgress(course?.courseId, parentLP?.courseId))

      }
    }

    // foreach LP save in redux this state
  }

  const checkIfCourseIsInSavingProgress = () => {
    
    const state = isCourseInSavingProgress(course)

    setShowSavingProgressCourse(state)
    if(!state){
      removeHandlerSavingProgress()
    } 

    if(isCompletedCourse(course) && isCourseInSavingProgress(course)){
      removeCookie(`${COOKIE?.COURSES_PROGRESS}_${course?.courseId}`);
      removeHandlerSavingProgress()
    }
  }

  const removeHandlerSavingProgress = ()=>{
    const handler = `handleInterval_${course?.courseId}`
    if(window[handler]){
      clearInterval(window[handler])
      console.log("Removed interval courseId:",course.courseId)
      delete window[handler]
    }

    dispatch(actions.unsetLPInSavingProgress(course?.courseId))
  }

  useEffect(saveLPInProgress,[bShowSavingProgressCourse])


  useEffect(function () {
   /* //<editor-fold desc="DetailsSingleActivityButton.tsx > LogCourse - line 1314 at 15/02/2024 10:23:22 by e.cavallo">
    if (course) {
      console.groupCollapsed('DetailsSingleActivityButton.tsx > LogCourse - line 1314 at 15/02/2024 10:23:22');
      console.table({'courseId': course.courseId, 'courseFullName': course.courseFullName, 'isCompleted': isCompletedCourse(course)})
      console.log(course)
      console.log(coursesMap)
      console.groupEnd();
    }*/
    if(isCompletedCourse(course)) checkIfCourseIsInSavingProgress()
    //</editor-fold>

  }, [course.userCourseStatus]);

  const openCourseModal = () => {
    const element = document.querySelector('.modal.modal__course-overview');
    if (element) {
      element.scrollBy({ top: 0, left: 0, behavior: 'auto' });
    }
    toggleModal(true, course);
  };

  const showPlayIconCondition =
    forcePlayIcon ||
    showPlayIcon ||
    (isChildOfAnEvent(course.courseId, coursesMap) &&
      isLessonWithinLp &&
      label === lang.JOIN_LABEL);

  // debugger
  if (useCustomButton) {
    // if(action) debugger
    const elementCustom = createElement(useCustomButton.typeElement, {
      // course: course,
      handleClickFromDetailsSingleActivityButton: e => {
        handleButtonClick(e);
      },
      ...useCustomButton.props,
    });

    const waitForGettingAction = !!useCustomButton?.useActionCustom;
    const elToPrint = waitForGettingAction ? elementCustom : action && elementCustom;

    return (
      <>
        {isLivestreamCourse(course) &&
          getLivestreamRecording(course, userProfile)?.length > 1 && //Multirecording case
          getLivestreamRecording(course, userProfile).map((recording, i) => {
            let urlCompletion = getLivestreamCompletation(course, userProfile, null, recording);
            let completeRecording = recording.concat('?api=1');
            if (urlCompletion) {
              return (
                <div>
                  <ButtonV2
                    variant="tertiary-vtransparent"
                    className="button-streaming-recording"
                    key={i}
                    handleClick={() => handleRecording(completeRecording, course, urlCompletion)}
                    ariaLabel={lang?.ACTIVITY_BUTTON_VIEW_RECORDING}
                  >
                    {lang?.ACTIVITY_BUTTON_VIEW_RECORDING +
                      ' - ' +
                      lang.PART_OF_RECORDING +
                      (i + 1)}
                  </ButtonV2>
                </div>
              );
            }
          })}
        {isLoading || (isWaitingCourseDetails && showPlayIconCondition) ? (
          <div className={clsx('launch-spinner', { 'with-play-icon': showPlayIconCondition })}>
            <CardLoader />
          </div>
        ) : (
          elToPrint
        )}
      </>
    );
  }

  
  
  if(!isCompletedCourse(course)  && !isLpBlocked(course, coursesMap) &&
    isCourseInSavingProgress(course)){
    const idInterval = `handleInterval_${course?.courseId}` 
    if(window[idInterval] === undefined){
      console.log("Added interval courseId:",course,course?.courseId);
      checkIfCourseIsInSavingProgress();
      window[idInterval] = setInterval(checkIfCourseIsInSavingProgress, 5000); //check if cookie expires every 5 seconds
    }
  }
 /* if(isCompletedCourse(course) && isCourseInSavingProgress(course)){
    removeHandlerSavingProgress()
  }*/

  return (
    <>
      {isLivestreamCourse(course) &&
        getLivestreamRecording(course, userProfile)?.length > 1 && //Multirecording case
        getLivestreamRecording(course, userProfile).map((recording, i) => {
          let urlCompletion = getLivestreamCompletation(course, userProfile, null, recording);
          let completeRecording = recording.concat('?api=1');
          if (urlCompletion) {
            return (
              <div>
                <ButtonV2
                  variant="tertiary-vtransparent"
                  className="button-streaming-recording"
                  key={i}
                  handleClick={() => handleRecording(completeRecording, course, urlCompletion)}
                  ariaLabel={lang?.ACTIVITY_BUTTON_VIEW_RECORDING}
                >
                  {lang?.ACTIVITY_BUTTON_VIEW_RECORDING + ' - ' + lang.PART_OF_RECORDING + (i + 1)}
                </ButtonV2>
              </div>
            );
          }
        })}
      {isLoading || (isWaitingCourseDetails && showPlayIconCondition) ? (
        <div
          className={clsx('launch-spinner', {
            'with-play-icon': showPlayIconCondition,
            [className]: className,
          })}
        >
          <CardLoader />
        </div>
      ) : (
        action && (
          <div
            className={clsx('launch-container', {
              [className]: className,
              'with-date-label': dateLabel || isMultiActivity(course),
              'with-play-icon': showPlayIconCondition && !isMultiActivity(course),
              'with-show-progress': bShowSavingProgressCourse,
              [classNameByAction]: classNameByAction,
            })}
          >
            {!ctaCard ? (
              <>
                {showPlayIconCondition ? (
                  <>
                    {isChildOfAnEvent(course.courseId, coursesMap) && (
                      <RibbonCourseEvolution
                        course={course}
                        className="tag-badge__event"
                        useBadgeLabelLive={false}
                        ribbonInfo={{
                          label: 'LIVE NOW',
                          color: 'red',
                          priority: 9999,
                        }}
                      />
                    )}
                    {/* {isMultiActivity(course) && !isChildOfAnEvent(course.courseId, coursesMap) ? (
                        <ButtonV2
                          variant={buttonVariant || buttonVariantByAction || 'primary'}
                          handleClick={() => openCourseModal()}
                          small={!!buttonSmall}
                          data-element-id={dataElementId}
                        >
                          {langNotif.GO_TO_LP}
                        </ButtonV2>
                      ) : ( */}
                      {bShowSavingProgressCourse && !isCompletedCourse(course) &&
                      (
                        <span>{lang?.COURSE_SAVING_PROGRESS}</span> 
                        )
                      }
                     <PlayIcon
                      className={clsx(
                        'play-icon__event-progress',
                        variantInsideCardEducationalPath && 'play-icon--card_education_path'
                      )}
                      valueBar={playIconPercentage || 0}
                      showProgressAsBorder={bShowSavingProgressCourse}
                      course={course}
                      courseMap={coursesMap}
                      data-element-id={dataElementId}
                      onClick={e => {
                        e?.stopPropagation();

                        if (!isLoadingButton() && !isDisabled()) {
                          handleButtonClick(e);
                        }
                      }}
                    />
                    {/* )} */}
                  </>
                ) : (
                  <>
                    {dateLabel && label !== lang.SIGNUP_CTA && (
                      <div className="launch-container__date-time">
                        <span className="launch-container__date-label">
                          {dateLabel.slice(0, 11)}
                        </span>
                        <span className="launch-container__hours-label">
                          {dateLabel.substring(11)}
                        </span>
                      </div>
                    )}
                    {label === lang.SAVE_CALENDAR &&
                      isChildOfAnEvent(course.courseId, coursesMap) &&
                      isLessonWithinLp && (
                        <ButtonV2
                          variant="text-btn-no-arrow"
                          className="add-to-calendar-button"
                          small
                          handleClick={() =>
                            createIcs(
                              course.courseIdMaster,
                              course.associatedSession?.id,
                              coursesMap,
                              userProfile?.timezone,
                              lang
                            )
                          }
                          ariaLabel={'add to calendar button'}
                        >
                          <LazySVG
                            src="/images/svg-icons/calendar-3.svg"
                            className="calendar-icon"
                            alt={lang.CARD_UPCOMING_SESSION_CALENDAR}
                          />
                          {lang.CARD_UPCOMING_SESSION_CALENDAR}
                        </ButtonV2>
                      )}

                    {(!isChildOfAnEvent(course.courseId, coursesMap) ||
                      (!isLessonWithinLp && isChildOfAnEvent(course.courseId, coursesMap)) ||
                      (label !== lang.SAVE_CALENDAR &&
                        isLessonWithinLp &&
                        isChildOfAnEvent(course.courseId, coursesMap))) && (
                      <>
                        <ButtonV2
                          loading={isLoadingButton()}
                          handleClick={handleButtonClick}
                          disabled={isDisabled()}
                          variant={buttonVariant || buttonVariantByAction || 'primary'}
                          small={!!buttonSmall}
                          data-element-id={dataElementId}
                          ariaLabel={label}
                        >
                          {!!harvardCTA ? (
                            <>{harvardCTA}</>
                          ) : (
                            <>
                              {label}
                              {suffix && !isOnboardingCompleted(course) && suffix}
                            </>
                          )}
                        </ButtonV2>

                        {labelCancelVC && (
                          <ButtonV2
                            loading={isLoadingButton()}
                            handleClick={actionCancelVC}
                            disabled={isDisabled()}
                            variant={buttonVariant || buttonVariantByAction || 'primary'}
                            small={!!buttonSmall}
                            data-element-id={dataElementId}
                            className="cancel-vc-btn"
                            addStopPropagation={true}
                            ariaLabel={labelCancelVC}
                          >
                            {!!harvardCTA ? (
                              <>{harvardCTA}</>
                            ) : (
                              <>
                                {labelCancelVC}
                                {suffix && !isOnboardingCompleted(course) && suffix}
                              </>
                            )}
                          </ButtonV2>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                <ButtonV2
                  className={`black mobile ${
                    !isVideoCourse(course) || !isStartedCourse(course) ? 'no-icon' : ''
                  }`}
                  variant="overlay-btn"
                  loading={isLoadingButton()}
                  handleClick={handleButtonClick}
                  disabled={isDisabled()}
                  children={!isVideoCourse(course) || !isStartedCourse(course) ? label : null}
                  data-element-id={dataElementId}
                  ariaLabel={'overlay button'}
                />
                <ButtonV2
                  className={`black desktop no-icon`}
                  variant="overlay-btn"
                  loading={isLoadingButton()}
                  handleClick={handleButtonClick}
                  disabled={isDisabled()}
                  children={!isVideoCourse(course) || !isStartedCourse(course) ? label : null}
                  data-element-id={dataElementId}
                  ariaLabel={'overlay button'}
                />
              </>
            )}

            {/* NOT USED FOR NOW */}
            {/* {label2 && action2 &&
              <ButtonV2
                loading={isLoadingButton()}
                handleClick={(e) => handleButtonClick(e, true)}
                disabled={isDisabled()}
                className="launch-container__second-button"
              >
                {label2}
              </ButtonV2>
            } */}
          </div>
        )
      )}
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    isLpEnrolling: state.course.isLpEnrolling,
    languages: state.utils.languages,
    userProfile: state.user.userProfile,
    coursesMap: state.course.coursesMap,
    showNextUpModal: state.course.showNextUpModal,
    isLoadingNotifyRecordingLivestream: state.firebase.isLoadingNotifyRecordingLivestream,
    isLoadingNotifyLivestreamStarting: state.firebase.isLoadingNotifyLivestreamStarting,
    isLoadingCourse: state.course.isLoadingCourse,
    plan: state.ecommerce.plan,
    isDownloadingOnboardingCertificatePdf: state.utils.isDownloadingOnboardingCertificatePdf,
  };
};


const mapDispatchToProps = dispatch => {
  return {
    toggleModal: (open, course, closeAll, scrollToCurrentCourseSessions) =>
      dispatch(actions.toggleModal(open, course, closeAll, scrollToCurrentCourseSessions)),
    toggleRatingModal: (val, course) => dispatch(actions.toggleRatingModal(val, course)),
    enrollLp: (enroll, id) => dispatch(actions.enrollLp(enroll, id)),
    openActivity: (activity, course, subtitle) =>
      dispatch(actions.openActivity(activity, course, subtitle)),
    toggleIframePopup: (
      value,
      course,
      url,
      urlToCheck,
      isVideo,
      urlsLivestream,
      activityId,
      subtitleCode
    ) =>
      dispatch(
        actions.toggleIframePopup(
          value,
          course,
          url,
          urlToCheck,
          isVideo,
          urlsLivestream,
          activityId,
          subtitleCode
        )
      ),
    openConfirmBookingModal: (courseId, session, isSessionSignUp) =>
      dispatch(actions.openConfirmBookingModal(courseId, session, isSessionSignUp)),
    toggleNextUpModal: val => dispatch(actions.toggleNextUpModal(val)),
    getCourseDetails: course => dispatch(actions.getCourseDetails(course)),
    handleCloseIframe: course => dispatch(actions.handleCloseIframe(course)),
    createSessionIcs: (courseId, sessionId) =>
      dispatch(actions.createSessionIcs(courseId, sessionId)),
    notifyRecordingLivestreamUpdate: course =>
      dispatch(actions.notifyRecordingLivestreamUpdate(course)),
    toggleModalCourseById: courseId => dispatch(actions.toggleModalCourseById(courseId)),
    setShowModalExpiredInPlan: (show: boolean) => dispatch(actions.setShowModalExpiredInPlan(show)),
    courseStarted: courseId => dispatch(actions.courseStarted(courseId)),
    downloadOnboardingCertificatePdf: courseId =>
      dispatch(actions.downloadOnboardingCertificatePdf(courseId)),
  };
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DetailsSingleActivityButton);
function handleClickScroll(arg0: number): void {
  const wrapper = document.querySelector('#course_overview_wrapper .custom-scrollbar-v3__scroller');
  const idToScroll = 'ID' + arg0;
  const activities = document.getElementById(idToScroll);
  activities.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
}
