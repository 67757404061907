import { Course, CoursesMap, LevelEnriched, LevelEnrichedFE, LevelEnrichedMap } from "@model/CoursesClass"
import { L1WithL2List } from "@hooks/useL1List";
import { BYC_IMAGE_BTN_HERO_MDP, BYC_IMAGE_BTN_HERO_PEOPLE, BYC_IMAGE_BTN_HERO_ROLE, BYC_IMAGE_BTN_HERO_SALES, BYC_IMAGE_BTN_HERO_SMP, BYC_IMAGE_BTN_HERO_VISION, LEVELS_AREA, SMP_IMAGE_BTN_HERO_BRANDS, ctype } from "@utility/const";
import * as urlList from "../config";
import axios from "axios";
import { getHeaders, isLearningPath } from "@utility/Api";
import { isCourseComingSoon } from "./CourseUtility";

/**
 * TYPES, INTERFACES AND ENUMS
 */
export enum EBuildYourCareerLevelsL0 {
  career_master = "career_master",
  career_career_programs = "career_career_programs",
  career_upskill_programs = "career_upskill_programs"
}

export enum EBuildYourCareerLevelsL1 {
  career_master = "career_master",
  career_front_desk_professional = "career_front_desk_professional",
  career_eye_care_dispenser = "career_eye_care_dispenser",
  career_practice_manager = "career_practice_manager",
  career_technician = "career_technician",
  career_brands = "career_brands",
  career_vision_care = "career_vision_care",
  career_sales_business = "career_sales_business",
  career_people_development = "career_people_development"
}

export enum EBycPageAnchors {
  career_programs = "role_specific_programs",
  upskill_programs = "specialty_programs",
  mdp_programs = "management_development_program",
}

export enum EUpskillPageAnchors {
  career_brands = "brands-and-products",
  career_vision_care = "vision-care",
  career_sales_business = "sales-business",
  career_people_development = "competency-hub",
}

export type TBannerTopButton = {
  anchorId: EUpskillPageAnchors,
  text: string,
  iconSrc: string,
  iconAlt?: string,
  imgSrc?: string,
  order?: number
}

export type TBycBannerTopButton = {
  anchorId: EBycPageAnchors,
  text: string,
  iconSrc: string,
  iconAlt?: string,
  imgSrc?: string,
  order?: number
}

export type BuildYourCareerInfos = {
  bycRolesCards: BycRoleCard[]
  RSPPageInfos: RoleSpecificProgramInfos,
  RSPPrograms: Course[],
  loadingRSPPrograms: boolean
}

export type BycRoleCard = {
  roleTitle: string,
  roleKey: string,
  coverImage: string,
  coverImageContentId: number,
  coverAlt: string,
}

export type RoleSpecificProgramInfos = {
  coverImage: string;
  contentId: number;
  curriculumTable: {
    curriculumLevels: RSPCurriculumLevels[];
    curriculumTimeInfos: RSPCurriculumTimeInfos[];
    curriculumTableContentId?: number;
  },
  coursesIds: Course["courseIdMaster"][]
  coursesContentId: number[];
};

type RSPCurriculumLevels = { key: string, level: number }
type RSPCurriculumTimeInfos = { icon: string, key: string, infos: string };

/**
 * --------------
 */

/**
 * BYC PAGE
 */

export const careerCardsPerRow = (careerCards: any) => {
  if (!careerCards) return
  
  switch (careerCards.length) {
    case 4:
    case 7:
    case 8:
      return 'four-per-row'
    case 3:
    case 5:
    case 6:
      return 'three-per-row'
    default:
      return ''
  }
}
/**
 * -------------
 */

/**
 * UTILS FUNCTIONS TO CLEAN CAREER PROGRAM DATA
 */
/**
 * @param course 
 * @returns TRUE if is a BuildYourCareer course, FALSE otherwise
 */
export const isCourseCareer = (course: Course): boolean => {
  return course
    && course.level0 === EBuildYourCareerLevelsL0.career_career_programs
    || course.level0 === EBuildYourCareerLevelsL0.career_upskill_programs
    || course.level0 === EBuildYourCareerLevelsL0.career_master
};

/**
 * @param l1Key 
 * @param coursesMap 
 * @returns TRUE if l1Key passed is a valid L1, FALSE otherwise
 */
export const isValidL1Career = (l1Key: string, coursesMap: CoursesMap): boolean => {
  return coursesMap && Object.values(coursesMap).some(c => c.catalogTypes.includes(l1Key))
};

export const isValidL1UpskillProgram = (course: Course, coursesMap: CoursesMap, labelL1Map: LevelEnrichedMap): boolean => {
  const l1Course = course?.catalogTypes[0];
  //const l1Key = Object.values(labelL1Map).find(l1 => (l1.label)?.toLowerCase()?.replace(/ /g, '-') === l1Label?.toLowerCase()?.replace(/ /g, '-'))?.key
  return l1Course
    ? Object.values(coursesMap).some(c => c.catalogTypes.includes(l1Course))
    : false
}

/**
 * @param course 
 * @returns TRUE if is a Career master, otherwise FALSE
 */
export const isCourseCareerMaster = (course: Course): boolean => {
  return course
    && isLearningPath(course)
    && (course?.complexity?.complexity === 'C8' || course?.complexity?.complexity === 'C13')
    && course.catalogTypes?.some((item: string) => item === EBuildYourCareerLevelsL1.career_master);
};

export const isRoleComingSoon = (coursesMap: CoursesMap, role: string) => {
  let roleCourses = coursesMap && Object.values(coursesMap).filter(course => {
    return course.catalogTypes.includes(role)
  })
  return roleCourses?.every(course => isCourseComingSoon(course))
}

/**
 * @param careerLevels 
 * @returns Array with all L1 of career_career_programs L0
 */
export const getCareerCareerProgramsL1 = (careerLevels: LevelEnrichedFE[]) => {
  return Object.values(careerLevels).filter(item => item.key === EBuildYourCareerLevelsL0.career_career_programs)[0]?.l1
}

/**
 * @param careerLevels 
 * @returns Array with all L1 of career_upskill_programs L0 or empty array if user doesn't have any
 */
export const getCareerUpskillProgramsL1 = (careerLevels: LevelEnrichedFE[]) => {
  return Object.values(careerLevels).filter(item => item.key === EBuildYourCareerLevelsL0.career_upskill_programs)[0]?.l1
    || []
}

/**
 * @param careerLevels 
 * @returns Ordered buttons (based on user L0s) to shown in BYC top page banner 
 */
export const getBycBannerTopButtons = (careerLevels: LevelEnrichedFE[], masters: Course[]) => {
  let orderedButtons: TBycBannerTopButton[] = []
  Object.values(careerLevels).map(l0 => {
    let bannerBtn = getBtnBycBannerTopButton(l0)
    if (l0.key === EBuildYourCareerLevelsL0.career_master && masters.length == 0) return
    if (bannerBtn) orderedButtons.push(bannerBtn)
  })
  return orderedButtons.sort((a, b) => a.order - b.order)
}

/**
 * @param l0 
 * @returns Button info based on L0
 */
const getBtnBycBannerTopButton = (l0: LevelEnrichedFE) => {
  switch (l0.key) {
    case EBuildYourCareerLevelsL0.career_career_programs:
      return {
        anchorId: EBycPageAnchors.career_programs,
        text: "BUILD_CAREER_BANNER_TOP_CAREER_PROGRAM",
        iconSrc: "/images/svg-icons/build-your-career/Iconset_user.svg",
        imgSrc: BYC_IMAGE_BTN_HERO_ROLE,
        order: l0?.order
      }
    case EBuildYourCareerLevelsL0.career_upskill_programs:
      return {
        anchorId: EBycPageAnchors.upskill_programs,
        text: "BUILD_CAREER_BANNER_TOP_UPSKILL_PROGRAM",
        iconSrc: "/images/svg-icons/build-your-career/Iconset_Page.svg",
        imgSrc: BYC_IMAGE_BTN_HERO_SMP,
        order: l0?.order
      }
    case EBuildYourCareerLevelsL0.career_master:
      return {
        anchorId: EBycPageAnchors.mdp_programs,
        text: "BUILD_CAREER_BANNER_TOP_MASTER_PROGRAM",
        iconSrc: "/images/svg-icons/build-your-career/Iconset_Master.svg",
        imgSrc: BYC_IMAGE_BTN_HERO_MDP,
        order: l0?.order
      }
    default: return null
  }

}

/**
 * @param upskillPrograms 
 * @returns Ordered buttons (based on user L1s) to shown in Upskill Programs top page banner 
 */
// export const getUpskillBannerTopButtons = (upskillPrograms: LevelEnriched[]) => {
export const getUpskillBannerTopButtons = (careerLevels: LevelEnrichedFE[]) => {
  if (careerLevels.length == 0) return []
  let upskillPrograms: LevelEnriched[] = getCareerUpskillProgramsL1(careerLevels);
  let orderedButtons: TBannerTopButton[] = []
  Object.values(upskillPrograms).map(item => {
    let bannerBtn = getBtnUpskillBannerTopButton(item)
    if (bannerBtn) orderedButtons.push(bannerBtn)
  })
  return orderedButtons.sort((a, b) => a.order - b.order)
}

/**
 * Filter in place all L2s for each L1 by Ctype equal to Learing Path
 * @param {L1WithL2List} l1s
 * */
export const filterL2sByCtypeLP = (l1s: L1WithL2List): void => {
  for (const l1 of Object.keys(l1s)) {
    const l2s = l1s[l1].l2
    if (l2s.length == 0) continue

    l1s[l1].l2 = l2s.filter(course => course.ctype.includes(ctype.LEARNING_PATH)) || []
  }
}

/*
* Fetch details of L2s passed. This don't update the catalog
*
* @param {Array<Course>} courses
* @param Setter of courses
* @param state of component parent, check if request is already sent
* @param setter state of component parent
* */
export const getCourseDetails = (courses: Array<Course>, setCourses, requestStarted, setRequestStarted): void => {
  const ids = courses.map(c => c.courseIdMaster)
  const queryParams = `?courseId=${[...ids].join(',')}`;
  const url = urlList.GET_COURSES_EDUCATIONAL_PATHS.replace('{courseIds}', queryParams);

  if (requestStarted) return
  axios({
    url,
    method: 'GET',
    headers: getHeaders(),
  })
    .then(response => {
      setCourses(response.data.learningPathCourses)
    })
    .catch(error => {
      // console.error("Error during EducationalPaths's Teachers download", error);
    });

  setRequestStarted(true)
}


/**
 * @param item 
 * @returns Button info based on L1
 */
const getBtnUpskillBannerTopButton = (item: LevelEnriched) => {
  switch (item.key) {
    case EBuildYourCareerLevelsL1.career_brands:
      return {
        anchorId: EUpskillPageAnchors.career_brands,
        text: item?.label,
        iconSrc: "/images/svg-icons/build-your-career/Iconset_Tag.svg",
        imgSrc: SMP_IMAGE_BTN_HERO_BRANDS,
        order: item?.order
      }
    case EBuildYourCareerLevelsL1.career_vision_care:
      return {
        anchorId: EUpskillPageAnchors.career_vision_care,
        text: item?.label,
        iconSrc: "/images/svg-icons/build-your-career/Iconset_Heart-outline.svg",
        imgSrc: BYC_IMAGE_BTN_HERO_VISION,
        order: item?.order
      }
    case EBuildYourCareerLevelsL1.career_sales_business:
      return {
        anchorId: EUpskillPageAnchors.career_sales_business,
        text: item?.label,
        iconSrc: "/images/svg-icons/build-your-career/Iconset_Bag-alt.svg",
        imgSrc: BYC_IMAGE_BTN_HERO_SALES,
        order: item?.order
      }
    case EBuildYourCareerLevelsL1.career_people_development:
      return {
        anchorId: EUpskillPageAnchors.career_people_development,
        text: item?.label,
        iconSrc: "/images/svg-icons/build-your-career/Iconset_Community.svg",
        imgSrc: BYC_IMAGE_BTN_HERO_PEOPLE,
        order: item?.order
      }
    default: return null
  }

}

/**
 * @param program 
 * @returns the string without "career_" and "-" instead of "_"
 */
export const formatProgramForCorrectUrl = (program: string) => {
  // @ts-ignore
  return program?.split('career_')[1].replaceAll('_', '-')
}
/**
 * @param program 
 * @returns the string with "career_" and "_" instead of "-"
 */
export const formatProgramFromUrl = (program: string) => {
  // @ts-ignore
  return `career_${program?.replaceAll('-', '_')}`
}