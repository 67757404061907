import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CardController from '@components/CardV3';
import AnimationSlide from '@components/animations/AnimationSlide';
import Carousel from '@components/UI/Carousel';
import { LangMap, Course } from '../model/CoursesClass';
import { USER_URLS } from './link-utils';
import CardL1Big from './CardV3/CardL1Big';
import { L1Element } from '@model/L1Element';
import { LearningPath } from './LearningPath/LPCardInterface';
import CarouselVariableWidth from './CarouselVariableWidth';
import ButtonV2 from './UI/ButtonV2';
import { useSelector } from '@hooks/redux';
import CardLargeEvolutionSkeleton from './skeletons/CardLargeEvolutionSkeleton';
import CardBigEvolutionSkeleton from './skeletons/CardBigEvolutionSkeleton';
import { useBreakpoint } from '@hooks/createBreakpoint';
import useInViewCarousel from '@hooks/useInViewCarousel';
import CardShowMore from './CardV3/CardShowMore';
import clsx from '@utility/clsx';
import { getCourseSummary, setCatalogueCourses } from '@redux-actions/';
import {
  CarouselTitleSkeletonPulse,
  useEditorialCarouselSkeletonPulseDimensions,
} from './skeletons/EditorialCarouselSkeletonPulse';
import { SKELETON_COLORS } from '@utility/const';
import ContentLoader from 'react-content-loader';
import { goToUrl, printHTML } from '@utility/Api';
import { analyticsPageType, purifyItemDataElementIdCamelCase, purifyItemDataElementIdandApplyCamelCasing, purifyItemDataElementIdandApplyCamelCasingBrandCase } from '@utility/analytics-utils';
import { CMSCarouselLayout, HPCarousel } from '@hooks/useHomepageCarousels';
import { cmMetaData } from '@utility/previewUtils';
import CardEducationalPathsProgramSkeleton from './skeletons/CardEducationalPathsProgramSkeleton';
import { useInView } from 'react-intersection-observer';
import { getTargetLinks } from './CMSNewPages/CMSMappingProps';

const animationSlide = {
  initialPosition: 'bottom',
  initialY: '20px',
  initialX: '0',
  intersectionObserver: true,
};

type Props = {
  lang: LangMap;
  courses?: Course[] | LearningPath[];
  elements?: JSX.Element[];
  title?: keyof LangMap | string;
  titleCatalogue?: keyof LangMap;
  description?: keyof LangMap | '';
  stringDescription?: string;
  htmlDescription?: string;
  subtitle?: string;
  subtitleAction?: () => void;
  subtitleDisabled?: boolean;
  classNames?: string;
  viewAllTo?: string;
  viewAllAction?: () => void;
  isLoading?: boolean;
  isLoadingTitleAndDescription?: boolean;
  isLoadingCourses?: boolean;
  isInHP?: boolean;
  numCoursesToShow?: number;
  l1List?: Array<L1Element>;
  hideTitle?: boolean;
  id?: string;
  stringTitle?: string;
  titleCtaLabel?: string;
  titleCtaOnClick?: () => void;
  disabledCta?: boolean;
  brandPage?: boolean;
  useOpacityOnCutCard?: boolean;
  forceShowPlusButton?: boolean;
  linkLabel?: string;
  skeletonElement?: JSX.Element;
  titleElement?: JSX.Element;
  afterContent?: JSX.Element;
  arrowsStyle?: 'tp-image';
  onCarouselScroll?;
  titleInCarousel?: boolean;
  carouselElementClass?: string;
  overwriteOnClickCard?: (course: Course) => void;
  beforeOnClickCard?: (course: Course, elementPosition: number) => void;
  hidePricelabel?: boolean;
  forceNoHover?: boolean;
  carouselPosition?: number;
  visibleCarousels?: HPCarousel[]
  isProductCarousel?: boolean,
  linkString?: string,
  animationSlideCustom?: any;
  carouselContentId?: number ,
  carouselCMSVariant?: string,
  carouselTitleVariant?: string,
  brand?: string,
} & (
    | {
      variableWidth?: boolean;
      variant?: string;
    }
    | {
      variant?:
      | 'card-evolution'
      | 'card-large-evolution'
      | 'carousel-collection'
      | 'latest-collection-carousel';
      variableWidth: true;
    }
  );

const CarouselSection = ({
  lang,
  courses = [],
  elements = [],
  title,
  description,
  stringDescription,
  subtitle,
  subtitleAction,
  subtitleDisabled,
  classNames = '',
  viewAllTo = USER_URLS.SUBCATALOGUE.URL,
  viewAllAction,
  hideTitle,
  isLoading = false,
  isLoadingTitleAndDescription = false,
  isLoadingCourses = false,
  isInHP,
  numCoursesToShow = 12,
  l1List,
  titleCatalogue,
  variant = 'card-evolution',
  variableWidth,
  stringTitle,
  titleCtaLabel,
  titleCtaOnClick,
  disabledCta = false,
  brandPage = false,
  useOpacityOnCutCard = true,
  isProductCarousel = false,
  linkString = "",
  id = 'carousel_' + (title || stringTitle || titleCatalogue)?.toLowerCase()?.replace(/[^\d\w]/g, '_'),
  forceShowPlusButton = false,
  linkLabel = '',
  skeletonElement,
  titleElement,
  afterContent,
  arrowsStyle,
  onCarouselScroll,
  titleInCarousel,
  overwriteOnClickCard,
  beforeOnClickCard,
  carouselElementClass,
  hidePricelabel,
  forceNoHover,
  animationSlideCustom,
  carouselPosition,
  visibleCarousels,
  carouselContentId,
  carouselCMSVariant,
  carouselTitleVariant,
  brand,
}: Props) => {
  const dispatch = useDispatch();
  const {isLoadingCourseSummary, coursesMap} = useSelector(state => ({
    isLoadingCourseSummary: state.course.isLoadingCourseSummary,
    coursesMap: state.course.coursesMap
  }));

  let [coursesToPrint, l1ToPrint, elementsNum] = elements.length > 0
    ? [elements, l1List, elements.length]
    : [courses, l1List, courses.length];

  if (coursesToPrint.length > numCoursesToShow) {
    coursesToPrint = coursesToPrint.slice(0, numCoursesToShow);
  }

  const handleShowMore = (): boolean => {
    if (viewAllAction) {
      viewAllAction();
      return false;
    } else if (courses) {
      dispatch(
        setCatalogueCourses(
          courses as Course[],
          titleCatalogue || title,
          variableWidth ? description : ''
        )
      );
      return true;
    }
  };

  const handleViewAll = () => {
    const redirect = handleShowMore();

    if (isProductCarousel && linkString) {
      window.open(linkString, getTargetLinks(linkString))
    } else if (redirect) {
      goToUrl(viewAllTo);
    }
  };

  const [carouselRef, carouselInView] = useInView();

  useEffect(() => {
    if(carouselCMSVariant === CMSCarouselLayout.PROGRAMS && carouselInView) {
      let coursesWithNoSummary = coursesToPrint.filter((course: Course) =>
        course?.courseId &&
        course?.courseSummary == undefined &&
        !isLoadingCourseSummary?.[course?.courseId]
      )
      if(coursesWithNoSummary.length > 0) {
        dispatch(getCourseSummary(coursesWithNoSummary, coursesMap))
      }
    }

  }, [carouselInView, carouselCMSVariant, coursesToPrint])

  const hasMoreCards = !!(
    courses.length > numCoursesToShow ||
    elements?.length > numCoursesToShow ||
    l1List?.length > numCoursesToShow
  );

  let carouselVariant = '';
  let cardVariant = '';
  switch (variant) {
    case 'card-evolution':
      carouselVariant = 'card-big-evolution ' + variant;
      cardVariant = variableWidth ? variant : 'big';
      break;
    case 'latest-collection-carousel':
    case 'carousel-collection':
      carouselVariant = variant;
      cardVariant = variant;
      break;
    default:
      carouselVariant = 'card-evolution ' + variant;
      cardVariant = variableWidth ? variant : 'big';
      break;
  }

  const showSkeleton = isInHP ? isLoadingCourses : isLoading

  const ContentCarousel = showSkeleton
    ? Array.from({ length: !variableWidth ? 6 : 13 }, (_, i) => (
      <div key={i}>
        {skeletonElement ? (
          skeletonElement
        ) : variant !== 'card-large-evolution' ? (
          variant !== 'card-educational-paths-section' ? (
            <CardBigEvolutionSkeleton />
          ) : (
            <CardEducationalPathsProgramSkeleton isInCarousel />
          )
        ) : (
          <CardLargeEvolutionSkeleton />
        )}
      </div>
    ))
    : l1List
      ? l1ToPrint?.map((l1, index) => (
        <CardL1Big
          l1={l1}
          key={l1.value + l1.image}
          elementPosition={index}
          beforeOnClickCard={beforeOnClickCard}
        />
      ))
      : coursesToPrint?.map((course, index) => (
        <CarouselElement
          key={course?.courseIdMaster || course?.l1?.value || course?.key}
          element={course}
          carouselId={id}
          cardVariant={cardVariant}
          className={carouselElementClass}
          useOpacityOnCutCard={useOpacityOnCutCard}
          isLast={index + 1 === courses.length}
          overwriteOnClickCard={overwriteOnClickCard}
          beforeOnClickCard={beforeOnClickCard}
          hidePricelabel={hidePricelabel}
          forceNoHover={forceNoHover}
          elementPosition={index}
          carouselName={lang?.[title] || stringTitle || ''}
          carouselPosition={carouselPosition}
          visibleCarousels={visibleCarousels}
          isProductCarousel={isProductCarousel}
        />
      ));

  const ShowMoreButton = variableWidth && !isLoading && hasMoreCards && !brandPage && (
    <CardShowMore variant={variant} onClick={handleViewAll} isProductCarousel={isProductCarousel} linkLabel={linkLabel} linkString={linkString} />
  );

  const scrollerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    const listItems = scrollerRef?.current?.querySelectorAll?.('.card-v3');
    listItems?.forEach(item => {
      item?.setAttribute?.('tabindex', '0');
      item?.setAttribute?.('aria-hidden', 'false');
    });

    // cleanup function on unmount
    return () => {
      listItems?.forEach(item => {
        item?.removeAttribute?.('tabindex');
        item?.removeAttribute?.('aria-hidden');
      });
    };
  }, [scrollerRef]);

  // const history = useHistory();

  const carouselTitleHandler = !!titleElement ? (
    titleElement
  ) : !isLoading || !variableWidth ? (
    <CarouselTitle
      carouselTitleAndDescriptionContentId={carouselContentId}
      hideTitle={hideTitle}
      title={title}
      stringTitle={stringTitle}
      showPlusButton={forceShowPlusButton || (hasMoreCards && variableWidth)}
      handleViewAll={handleViewAll}
      titleCtaLabel={titleCtaLabel}
      titleCtaOnClick={titleCtaOnClick}
      disabledCta={disabledCta}
      description={description}
      stringDescription={stringDescription}
      subtitle={subtitle}
      subtitleAction={subtitleAction}
      subtitleDisabled={subtitleDisabled}
      linkLabel={linkLabel}
      titleInCarousel={titleInCarousel && variableWidth && !isLoading}
      carouselTitleVariant={carouselTitleVariant}
      brand={brand}
    />
  ) : (
    <CarouselTitleSkeleton />
  );

  useOpacityOnCutCard ? useBreakpoint() : null; // don't  remove this line, it's used for a shadow bug

  return (
    <AnimationSlide
      {...animationSlide}
      {...animationSlideCustom}
      // SET initialOpacity to 1 for carousels to prevent blank spaces
      initialOpacity={1}
    >
      <div
        className={clsx('carousel-section carousel-wrapper section', {
          'variable-width': variableWidth,
          loading: isLoading,
          [classNames]: classNames,
          [carouselVariant]: carouselVariant,
          'carousel-section--title-in-carousel': titleInCarousel,
        })}
        id={id}
        {...carouselContentId && cmMetaData(carouselContentId)}
        ref={carouselRef}
        role='region' aria-label={`carousel section ${lang?.[title]}`}
      >
        {variableWidth ? (
          <CarouselVariableWidth
            ContentCarousel={ContentCarousel}
            SkeletonCarousel={ContentCarousel}
            ShowMoreButton={ShowMoreButton}
            isLoading={isLoading}
            variant={carouselVariant}
            titleComponent={carouselTitleHandler}
            titleInCarousel={titleInCarousel}
            arrowsStyle={arrowsStyle}
            onCarouselScroll={onCarouselScroll}
            carouselPosition={carouselPosition}
            carouselName={stringTitle}
          />
        ) : (
          <>
            {carouselTitleHandler}
            <Carousel
              numSlides={isLoading ? 6 : elementsNum}
              showSlider={true}
              variant={carouselVariant}
            >
              {ContentCarousel}
              {ShowMoreButton}
            </Carousel>
            {hasMoreCards && (
              <section
                className="section__button"
                onClick={handleShowMore}
                aria-label={lang.SEE_ALL_LABEL}
              >
                <ButtonV2 variant="secondary" to={viewAllTo} ariaLabel={lang.SEE_ALL_LABEL}>
                  {lang.SEE_ALL_LABEL}
                </ButtonV2>{' '}
              </section>
            )}
          </>
        )}
        {!!afterContent && afterContent}
      </div>
    </AnimationSlide>
  );
};

export default CarouselSection;

type CarouselTitle = {
  className?: string;
  hideTitle?: boolean;
  title?: string;
  stringTitle?: string;
  showPlusButton: boolean;
  viewAllKeyLabel?: string;
  handleViewAll: () => void;
  titleCtaLabel?: string;
  titleCtaOnClick?: () => void;
  disabledCta?: boolean;
  description?: string;
  stringDescription?: string;
  subtitle?: string;
  subtitleAction?: () => void;
  subtitleDisabled?: boolean;
  linkLabel?: string;
  titleInCarousel?: boolean;
  carouselTitleAndDescriptionContentId?: number;
  carouselTitleVariant?: string;
  brand?: string;
};

export const PlusButton = ({ handleViewAll, viewAllKeyLabel = 'SEE_ALL_LABEL'}) => {
  const lang = useSelector(state => state.utils.lang);

  return (
    <ButtonV2
      variant="text-btn"
      small
      className="plus-button"
      handleClick={handleViewAll}
      aria-label={lang?.[viewAllKeyLabel] || viewAllKeyLabel}
    >
      {lang?.[viewAllKeyLabel] || viewAllKeyLabel}
    </ButtonV2>
  );
};

export const CarouselTitle = ({
  className = '',
  hideTitle = false,
  title,
  stringTitle,
  showPlusButton,
  handleViewAll,
  titleCtaLabel = '',
  titleCtaOnClick,
  disabledCta,
  description = '',
  stringDescription = '',
  subtitle = '',
  subtitleAction,
  subtitleDisabled,
  linkLabel = '',
  titleInCarousel = false,
  viewAllKeyLabel,
  carouselTitleAndDescriptionContentId,
  carouselTitleVariant,
  brand,
}: CarouselTitle) => {
  const lang: LangMap = useSelector(state => state.utils.lang);
  if (!viewAllKeyLabel || linkLabel) {
    viewAllKeyLabel = linkLabel ? linkLabel : 'SEE_ALL_LABEL';
  }

  const langTitle = lang?.[title];
  const brandTitle = brand ? langTitle?.replace("{brand}", brand) : langTitle;

  return (
    <section
      className={
        'section__header ' +
        (className ? className : '') +
        (titleInCarousel ? ' title-in-carousel' : '') +
        (carouselTitleVariant? carouselTitleVariant : '')
      }
      aria-label={lang?.[title] || title || stringTitle}
    >
      <div className="section__wrapper">
        <div className="carousel__wrapper-text">
          <div className="carousel__title-row">
            <div className={clsx(
              "carousel__title-row-left",
              {[carouselTitleVariant]: carouselTitleVariant}
            )}
                 {...carouselTitleAndDescriptionContentId && cmMetaData(carouselTitleAndDescriptionContentId)}>
              {!hideTitle ? (
                <h2 className="typography-primary-h2-left-gradient uppercase">
                  {brandTitle || stringTitle || ''}
                </h2>
              ) : (
                <div style={{height: '41.6px'}}></div>
              )}
              {showPlusButton && !titleInCarousel && (
                <PlusButton
                  viewAllKeyLabel={linkLabel ? linkLabel : 'SEE_ALL_LABEL'}
                  handleViewAll={handleViewAll}
                />
              )}
            </div>
            {titleCtaLabel && (
              <ButtonV2
                className="section__title__cta white"
                variant="text-btn-no-arrow"
                small
                handleClick={titleCtaOnClick}
                disabled={disabledCta}
                ariaLabel={titleCtaLabel}
              >
                {titleCtaLabel}
              </ButtonV2>
            )}
          </div>
          {((description && lang?.[description]) ||
            stringDescription ||
            (subtitle && subtitleAction)) && (
            <div className="section__description-wrapper">
              {((description && lang?.[description]) || stringDescription) && (
                <p
                  className="section__description"
                  dangerouslySetInnerHTML={printHTML(lang[description] || stringDescription)}
                  {...carouselTitleAndDescriptionContentId && cmMetaData(carouselTitleAndDescriptionContentId)}
                ></p>
              )}
              {subtitle && subtitleAction && (
                <ButtonV2
                  variant="text-btn-no-arrow"
                  small
                  className="section__carouselsubtitle text-14"
                  handleClick={subtitleAction}
                  disabled={subtitleDisabled}
                  ariaLabel={subtitle}
                >
                  {subtitle}
                </ButtonV2>
              )}
            </div>
          )}
        </div>
      </div>
      {showPlusButton && titleInCarousel && (
        <PlusButton
          viewAllKeyLabel={linkLabel ? linkLabel : 'SEE_ALL_LABEL'}
          handleViewAll={handleViewAll}
        />
      )}
    </section>
  );
};

export const CarouselTitleSkeleton = () => {
  const dimensions = useEditorialCarouselSkeletonPulseDimensions();
  const fullHeight =
    dimensions.title.height +
    dimensions.title.marginBottom +
    dimensions.description.height +
    (dimensions.description2.height ? dimensions.description.marginBottom : 0) +
    dimensions.description2.height;
  // console.log('dimensions', dimensions)

  return (
    <div
      className="section__header carousel-title-skeleton"
      style={{
        width: `${dimensions.title.width}px`,
        height: `${fullHeight}px`,
        overflow: 'hidden',
      }}
    >
      <ContentLoader
        speed={2}
        width={dimensions.title.width}
        height={fullHeight}
        viewBox={`0 0 ${dimensions.title.width} ${fullHeight}`}
        primaryColor={SKELETON_COLORS.primaryColor}
        secondaryColor={SKELETON_COLORS.secondaryColor}
      >
        <CarouselTitleSkeletonPulse dimensions={dimensions} />
      </ContentLoader>
    </div>
  );
};

export const CarouselElement = ({
  element,
  carouselId,
  cardVariant,
  useOpacityOnCutCard,
  isLast,
  className,
  overwriteOnClickCard,
  beforeOnClickCard,
  hidePricelabel,
  forceNoHover,
  elementPosition,
  carouselName,
  visibleCarousels,
  carouselPosition,
  isProductCarousel,

}) => {
  const [ref, isVisible] = isProductCarousel ? useInViewCarousel("carousel-card__wrapper", false, false) :  useInViewCarousel(carouselId);

  const pageType = analyticsPageType();
  const carousel = visibleCarousels && visibleCarousels.find((carousel) => carouselName === carousel.title)
  const isL1Carousel = () => element?.ctype?.includes('l1')

  let dataElementId = '';
  if (pageType === 'home') {
    dataElementId = `${pageType}_${purifyItemDataElementIdCamelCase(carouselName?.toLowerCase())}N${carousel?.position}_${!isL1Carousel() ? element?.courseId : purifyItemDataElementIdCamelCase(element?.l1?.label)}N${elementPosition}`
  }
  if (pageType === 'category') {

    if (element?.catalogTypes?.[0]?.includes("brand")) { //brand page
      dataElementId = `Catalogue_${purifyItemDataElementIdandApplyCamelCasingBrandCase(element?.catalogTypes?.[0])}_${purifyItemDataElementIdandApplyCamelCasing(carouselName)}N${carouselPosition}_${element.courseId}N${elementPosition}`;
    }
    else {
      dataElementId = `Catalogue_Channel${purifyItemDataElementIdandApplyCamelCasing(element?.catalogTypes?.[0])}_${purifyItemDataElementIdandApplyCamelCasing(carouselName)}N${carouselPosition}_${element.courseId}N${elementPosition}`;
    }
  }

  const scrollToEnd = () => {
    if (isLast && isVisible) {
      const root = document.querySelector('#' + carouselId + ' .carousel-scroller');
      if (root) {
        root.scrollLeft = root.scrollWidth;
      }
    }
  };

  return (
    <div
      ref={ref}
      className={
        'carousel-section__element-wrapper ' +
        (!useOpacityOnCutCard || isVisible ? '' : 'carousel-section__element-not-visible')
      }
    >
      {React.isValidElement(element) ? (
        element
      ) : (
        <CardController
          course={element}
          variant={cardVariant}
          onHover={scrollToEnd}
            noHoverEffect={forceNoHover || !isVisible}
          overwriteOnClick={overwriteOnClickCard}
          beforeOnClickCard={beforeOnClickCard}
          className={className}
          hidePricelabel={hidePricelabel}
          elementPosition={elementPosition}
          data-element-id={dataElementId}
          isProductCarousel={isProductCarousel}
        />
      )}
    </div>
  );
};
