import React, {useEffect} from 'react';
import CardLoader from '@components/UI/CardLoader';
import { useHistory } from 'react-router-dom';
import ModalRight from '@components/UI/ModalRight';
import { useDispatch } from 'react-redux';
import { useSelector } from '@hooks/redux';
import { toFixedNumString } from '@utility/ecommerceUtility';
import { analyticsOpenModal, setAutorenewalActive } from '@redux-actions/';
import useToggle from '@hooks/useToggle';
import { TealiumEvents } from '@model/TrackingClass';
import { ProductCart } from '@model/CartModel';
import CartCardModal from './CartCardModal';
import { USER_URLS } from './link-utils';
import ButtonV2 from './UI/ButtonV2';
import { formatCurrency } from '@utility/Api';
import { UserProfile } from '@model/User';
import useModalTypes from '@hooks/useModalByType';
import { useBreakpointBoolean } from '@hooks/createBreakpoint';
import useCart from '@hooks/useCart';
import selectPlanStates from '@utility/ecommercePlanUtility';

type CartRightModalProps = {
  show: boolean;
};

const CartRightModal = (props: CartRightModalProps) => {
  const lang = useSelector(state => state.utils.lang);

  const isLoading: boolean = useSelector(
    state =>
      state.ecommerce.isLoadingCart ||
      !state.course.isGetCourseCompleted ||
      state.ecommerce.isLoadingPlan
  );
  const dispatch = useDispatch();

  const history = useHistory();
  const modalTypes = useModalTypes();
  const cart = useCart();
  const plan = useSelector(state => state.ecommerce.plan);
  const cartItems = cart?.items;
  const { numItems, errorLabel } = cart;
  const removeCartItemLoading = useSelector(state => state.ecommerce.isLoadingRemoveItemCart);
  const [toggleActive, setToggleActive] = useToggle(false);
  const breakpoint = useBreakpointBoolean();
  const timeOnTraining = useSelector(state => state.course.timeOnTraining);
  const planStates = useSelector(selectPlanStates);

  const handleToggleActive = (active: boolean) => {
    setToggleActive(active);
    const product = new ProductCart(plan.id.toString(), 1);
    dispatch(setAutorenewalActive(active));

    window?.utag?.link({
      id: active ? TealiumEvents.renewal_on : TealiumEvents.renewal_off,
      Products_Category_Array: ['plan'],
      product_subcategory: [''],
      Products_Id_Array: [product.partNumber],
      Products_ModelName_Array: [lang?.UNLIMITED_ACCESS_PLAN || product.partNumber],
      product_format: ['plan'],
      Products_Mandatory_Array: ['N'],
      Products_Units_Array: [product?.quantity?.toString()],
      Products_PriceFull_Array: [toFixedNumString(plan?.price || 0)],
      Products_RevenuePerUnit_Array: [toFixedNumString(product?.orderItemPrice)],
      licence_destination: ['me'],
      Order_CartId: cart.orderId,
      Page_DeviceType: 'web',
      Order_Currency: userProfile?.ecommerceInfo?.currency,
    });
  };

  useEffect(()=>{
    if(props.show){
      dispatch(analyticsOpenModal('Add-to-Cart Popin'))
    }
  },[])
  
  const userProfile: UserProfile = useSelector(state => state.user.userProfile);

  const handleViewCart = () => {
    if (!errorLabel) {
      history.push(USER_URLS.CART.URL);
      closeModal();
    }
  };

  const closeModal = () => {
    modalTypes.close();
  };

  return (
    <ModalRight
      show={props.show}
      close={closeModal}
      eventCloseOnClick={closeModal}
      className="cart-right-modal"
      animateSlide
      ignoreBlock={true}
    >
      <section aria-label={lang.CART}>
        <h1 className="cart-right-modal__title">
          <span>{lang.CART}</span>
          {numItems > 0 ? <span>{numItems}</span> : null}
        </h1>
        <div className="cart-right-modal__items">
          {cartItems?.map(
            cartItem =>
              cartItem && (
                <CartCardModal
                  key={cartItem.id}
                  cartItem={cartItem}
                  removing={removeCartItemLoading}
                />
              )
          )}

          {!!errorLabel && (
            <p className="flex align-center cart-right-modal__error" role="alert">
              <span>{errorLabel}</span>
            </p>
          )}

          {isLoading && (
            <div className="wrapper notificationV3__loader">
              <CardLoader />
            </div>
          )}

          {/* should modal be open also when no items? */}

          {!cartItems?.length && !isLoading && !errorLabel && (
            <div className="cart-right-modal__no-results">
              <p>{lang.NO_RESULTS}</p>
            </div>
          )}
        </div>
      </section>
      <section className="cart-right-modal__bottom" role="region" aria-label={lang?.SUBTOTAL_CART}>
        <div className="cart-right-modal__summary flex justify-between">
          <p>{lang?.SUBTOTAL_CART?.toUpperCase()}</p>
          <p>
            {cartItems && cartItems.length === 1 && cartItems[0].isPlan && planStates && planStates?.isPlanFree
              ? formatCurrency(
                0,
                cart?.totalProductCurrency,
                userProfile?.preferredLang
              )
              : formatCurrency(
                cart?.totalProductPrice,
                cart?.totalProductCurrency,
                userProfile?.preferredLang
              )
            }
          </p>
        </div>
        <ButtonV2
          variant="primary"
          className="width-100"
          disabled={!!errorLabel}
          handleClick={handleViewCart}
          loading={isLoading}
          ariaLabel={lang?.VIEW_CART.toUpperCase()}
        >
          {lang?.VIEW_CART.toUpperCase()}
        </ButtonV2>
      </section>
    </ModalRight>
  );
};

export default CartRightModal;
